import React, { useState, useEffect } from "react";
import axios from "axios";

import ListGedamat from "./components/lists/ListGedamat";

function BeenteGedamat() {
  const [dataGedamat, setDataGedamat] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`/api/beentegedam/?page=${currentPage}`);
      setDataGedamat(response.data.results);
      setPageCount(response.data.total_pages);
    } catch (error) {
      console.log(error);
    }
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };
  return (
    <div className="w-full">
      <h1 className="text-3xl">በእንተ ገዳማት</h1>
      <ListGedamat meetups={dataGedamat} />
      <div className="flex justify-evenly">
        <button
          className="flex items-center justify-center px-4 h-10 text-base font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          onClick={previousPage}
          disabled={currentPage === 1}
        >
          ቀፃሊ
        </button>
        <button
          className="flex items-center justify-center px-4 h-10 ml-3 text-base font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          onClick={nextPage}
          disabled={currentPage === pageCount}
        >
          ሕሉፍ
        </button>
      </div>
    </div>
  );
}

export default BeenteGedamat;
