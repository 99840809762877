import React from 'react'
function AboutPage(){
    return <article class="flex flex-col w-full shadow my-4 bg-white">
    <header class="w-full container mx-auto border-b-4 md:border-b-2">
      <div class="flex justify-start items-center pl-7 py-3">
        <span class="font-bold text-secondary-grayd uppercase text-lg text-center"
          >ብዛዕባና</span
        >
      </div>
    </header>
    <div class="bg-white flex flex-col justify-start p-6">
      <span class="pb-6 font-mono">
        ንሕና ናይ ትግራይ ኦርቶዶክስ ተዋህዶ ቤተክርስትያን... እዚ ሳይት ንዓኹም ሓበሬታ ንምሃብ ተዳልዩ</span
      >
      
    </div>
  </article>
}
export default AboutPage;
