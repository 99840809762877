import React from "react";
import classes from "./List.module.css";
import ItemGedamat from "./ItemGedamat";
function ListGedamat(props) {
  return (
    <ul className={classes.list}>
      {props.meetups.map((meetup) => (
        <ItemGedamat
          key={meetup.id}
          id={meetup.id}
          name={meetup.name}
          image={meetup.image}
          history={meetup.history}
          address={meetup.address}
        />
      ))}
    </ul>
  );
}

export default ListGedamat;
