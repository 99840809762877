import React from "react";
import { Routes, Route, UNSAFE_DataRouterContext } from "react-router-dom";

import ContactUs from "./ContactUs";
import AboutPage from "../../pages/AboutUs";
import AddHomePageContent from "../../pages/AddHomepageContent";
import HomePage from "../../pages/Home";
import BeenteGedamat from "../../BeenteGedamat";
import AwdeMihret from "../../pages/AwdeMihret";
import News from "../../pages/Newsleaset";
import MainSection from "./MainSection";
import EduArticle from "./DetailContent";
import { data, id } from "./Item.gpt";
function RouteMap() {
  return (
    <Routes>
      <Route path="/" exact element={<MainSection page={<HomePage />} />} />
      <Route
        path="/addContentHP"
        exact
        element={<MainSection page={<AddHomePageContent />} />}
      />
      <Route
        path="/contactUs"
        exact
        element={<MainSection page={<ContactUs />} />}
      />
      <Route
        path="/aboutUs"
        exact
        element={<MainSection page={<AboutPage />} />}
      />
      <Route
        path="/gedamatna"
        exact
        element={<MainSection page={<BeenteGedamat />} />}
      />
      <Route
        path="/awdemihret"
        exact
        element={<MainSection page={<AwdeMihret />} />}
      />
      <Route path="/news" exact element={<MainSection page={<News />} />} />
      <Route
        path={"/regEdus/:id"}
        exact
        element={<MainSection page={<EduArticle />} />}
      />
    </Routes>
  );
}

export default RouteMap;
