import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { paraID } from "./MainSection";
function EduArticle(props) {
  const id = paraID;
  const [dataEduDetail, setDataEduDetail] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (selectedPage = 0) => {
    try {
      const response = await axios.get(
        `http://192.168.1.7:8000/api/regEdu/${id}/`
      );
      setDataEduDetail(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <article className="flex flex-col text shadow  bg-white">
      <div className="bg-white flex flex-col justify-start p-6">
        <header className="w-full container mx-auto border-b-4 md:border-b-2">
          <div className="flex justify-start items-center pl-7 ">
            <span className="font-bold text-secondary-grayd uppercase text-lg text-center">
              {dataEduDetail.title}
            </span>
          </div>
        </header>
        <span className="pb-6 font-mono text-justify">
          {dataEduDetail.detail}
        </span>
        <a href="#" className="text-lg font-bold hover:text-primary pb-4">
          {dataEduDetail.source}
        </a>
        <p className="text-sm pb-3">
          ብ
          <a href="#" className="font-semibold hover:text-primary">
            {dataEduDetail.preparedBy}
          </a>
          , {"ዓመተ ምህረት"} ዓ/ም
        </p>
      </div>
    </article>
  );
}
export default EduArticle;
