import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom'
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('head'));
root.render(
<Router>
  <App>
  </App>
</Router>
);

