import {useNavigate} from 'react-router-dom'
import React from "react";
//import axios from "axios";

import NewListForm from "../components/lists/NewContentForm";

function AddHomePageContent(){

    const navigate = useNavigate();

    function addHomeHandler(contentData) {
        fetch(
          'http://127.0.0.1:8000/api/addContent/',
          {
            method: 'POST',
            body: JSON.stringify(contentData),
            headers: {
              'Content-Type': 'application/json'
            }
          }
        ).then(() => {
            navigate('/', { replace: true })
        });
      }
    
      return (
        <section>
          <h1>Add Content</h1>
          <NewListForm onAddMeetup={addHomeHandler} />
        </section>
      );
}

export default AddHomePageContent;
