import React from "react";

function Section(props) {
  return (
    <section class="w-full md:w-2/3 flex flex-col items-center px-3">
      {props.page}
    </section>
  );
}
export default Section;
