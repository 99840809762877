import React from "react";
import Section from "./Section";
import ReactDOM from "react-dom";
import SideBar from "./SideBar";
import Footer from "./Footer";
import "../../index.css";
import { useParams } from "react-router-dom";
export var paraID;
function MainSection(props) {
  const { id } = useParams();
  paraID = id;
  console.log(paraID);
  ReactDOM.createRoot(document.getElementById("mainDiv")).render(
    <div class="container mx-auto flex  flex-wrap ">
      <Section page={props.page} />
      <SideBar />
      <Footer />
    </div>
  );
}

export default MainSection;
