import React from "react";

function SideBar() {
  return (
    <aside class="w-full md:w-1/3 flex flex-col items-center px-3">
      <div class="w-full bg-white shadow flex flex-col my-4 p-6">
        <p class="text-xl font-semibold pb-5">ናይ ዩቲብ ኣድራሻና</p>
        <p class="pb-2">በዚ ዝስዕብ መመልከቲ ብምኽታል ናይ ዩቲብ ቻናልና ይከታተሉ</p>
        <a
          href="https://www.youtube.com/channel/UCgGDOfYsFt8mRd3kggsyLQQ"
          target="_blank"
          class="w-full bg-primary text-white font-bold text-sm uppercase rounded hover:text-secondary-grayel hover:shadow-inner transform hover:scale-125 flex items-center justify-center px-2 py-3 mt-4"
        >
          ዩቱብ ቻናል
        </a>
      </div>
      <div class="w-full bg-white shadow flex flex-col my-4 p-6">
        <p class="text-xl font-semibold pb-5">ናይ ፊስ ቡክ ኣድራሻና</p>
        <p class="pb-2">በዚ ዝስዕብ መመልከቲ ብምኽታል ናይ ፊስ ቡክ ገጽ ይከታተሉ</p>
        <a
          href="https://www.facebook.com/mkyzott"
          target="_blank"
          class="w-full bg-primary text-white font-bold text-sm uppercase rounded hover:text-secondary-grayel hover:shadow-inner transform hover:scale-125 flex items-center justify-center px-2 py-3 mt-4"
        >
          ፊስ ቡክ ገጽ
        </a>
      </div>
      <div class="w-full bg-white shadow flex flex-col my-4 p-6">
        <p class="text-xl font-semibold pb-5">ናይ ቴሊ ግራም ኣድራሻና</p>
        <p class="pb-2">በዚ ዝስዕብ መመልከቲ ብምኽታል ናይ ቴሊ ግራም ቻናልና ይከታተሉ</p>
        <a
          href="#"
          class="w-full bg-primary text-white font-bold text-sm uppercase rounded hover:text-secondary-grayel hover:shadow-inner transform hover:scale-125 flex items-center justify-center px-2 py-3 mt-4"
        >
          ዩቱብ ቻናል
        </a>
      </div>
    </aside>
  );
}

export default SideBar;
