import React from 'react'
function Footer() {
  return (
    <footer className="w-full border-t bg-secondary-grayeel pb-12">
      <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400 mr-4">
          © 2023
          <a href="https://www.facebook.com/hastechnologysolutions" className="hover:underline hover:text-primary" target="_blank">
            HASTECH™
          </a>
          . All Rights Reserved.
        </span>
        <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
          <li>
            <a
              href="/aboutUs"
              className="mr-4 hover:underline hover:text-primary md:mr-6"
            >
              ብዛዕባና
            </a>
          </li>
          <li>
            <a
              href="/contactUs"
              className="mr-4 hover:underline hover:text-primary md:mr-6"
            >
              ይርከቡና
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}
export default Footer;
