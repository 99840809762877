import React from "react";
import DropdownNav from "./Item.gpt";

function List() {
  return (
    <ul className="flex justify-evenly w-full container mx-auto flex-col sm:flex-row items-start text-secondary-grayd text-sm font-bold uppercase mt-0 md:px-20 py-2">
      <li>
        <a href="/" className="btn block">
          ቀዳሚ ገጽ
        </a>
      </li>
      <DropdownNav />
      <li>
        <a href="/awdemihret" className="btn block">
          ዓውደ ምህረት
        </a>
      </li>
      <li>
        <a href="/gedamatna" className="btn block">
          በእንተ ገዳማት
        </a>
      </li>
      <li>
        <a href="/news" className="btn block">
          ዜና
        </a>
      </li>
    </ul>
  );
}

export default List;
