import React from "react";
import classes from "./Item.module.css";
import Card from "./ui/Card";
function ItemAwdemihret(props) {
  return (
    <li className={classes.item}>
      <Card>
        <div className={classes.content}>
          <h3>{props.title}</h3>
          <h3>{props.reference}</h3>
          <p className="text-justify">{props.detail}</p>
          <address>{props.preparedBy}</address>
        </div>
        <div className={classes.actions}>
          <button>To Favorites</button>
        </div>
      </Card>
    </li>
  );
}

export default ItemAwdemihret;
