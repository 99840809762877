function ContactUs() {
  return (
    <article className="flex flex-col  shadow my-4">
      <section className="bg-white f dark:bg-gray-900 w-full">
        <div className="container px-6 py-12 mx-auto">
          <header className="w-full  container mx-auto border-b-4 md:border-b-2">
            <div className="flex justify-start items-center pl-7 py-3">
              <span className="font-bold text-secondary-grayd  text-lg text-center">
                ይርከቡና
              </span>
            </div>
          </header>

          <div className="mt-8">
            <div className="p-4 py-6 rounded-lg bg-gray-50 dark:bg-gray-800 md:p-8">
              <form>
                <div className="-mx-2 md:items-center md:flex">
                  <div className="flex-1 px-2">
                    <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">
                      ሽም
                    </label>
                    <input
                      type="text"
                      placeholder="ገ/የሱስ"
                      className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-primary dark:focus:border-primary focus:ring-primary focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                  </div>

                  <div className="flex-1 px-2 mt-4 md:mt-0">
                    <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">
                      ሽም ኣቦ
                    </label>
                    <input
                      type="text"
                      placeholder="ሃደራ"
                      className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-primary dark:focus:border-primary focus:ring-primary focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                  </div>
                </div>

                <div className="mt-4">
                  <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">
                    ናይ ኢሜይል ኣድራሻ
                  </label>
                  <input
                    type="email"
                    placeholder="johndoe@example.com"
                    className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-primary dark:focus:border-primary focus:ring-primary focus:outline-none focus:ring focus:ring-opacity-40"
                  />
                </div>

                <div className="w-full mt-4">
                  <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">
                    መልእቲ
                  </label>
                  <textarea
                    className="block w-full h-32 mb-6 px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg md:h-56 dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-primary dark:focus:border-primary focus:ring-primary focus:outline-none focus:ring focus:ring-opacity-40"
                    placeholder="መልእኽቲ"
                  ></textarea>
                </div>

                <button className="btn3">መልእኽቲ ኣብፅሕ</button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </article>
  );
}
export default ContactUs;
