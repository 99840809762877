import React from 'react'

function Nav() {
  return (
    <div>
      <nav className="w-full py-4 bg-primary shadow ">
        <ul
          className="flex justify-center md:justify-end font-bold text-sm text-white uppercase no-underline"
        >
          <li>
            <a href="/aboutUs" className="hover:text-secondary-grayel hover:underline"
              >ብዛዕባና</a
            >
          </li>
          <li>
            <a
              href="/contactUs"
              className="hover:text-secondary-grayel hover:underline ml-5 mr-10"
              >ርኸቡና</a
            >
          </li>
        </ul>
      </nav>
      </div>
    )
}
export default Nav;