import React from "react";
import classes from "./List.module.css";
import ItemAwdemihret from "./ItemAwdemihret";
function ListAwedemihret(props) {
  return (
    <ul className={classes.list}>
      {props.meetups.map((meetup) => (
        <ItemAwdemihret
          key={meetup.id}
          id={meetup.id}
          reference={meetup.reference}
          title={meetup.title}
          detail={meetup.detail}
          preparedBy={meetup.preparedBy}
        />
      ))}
    </ul>
  );
}

export default ListAwedemihret;
