import { useState } from "react";
import { FiMenu } from "react-icons/fi";
import List from "./List";
import logo121 from "./logo121.png";

function NavMid() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  return (
    <div>
      <header className="w-full container mx-auto border-b-4 md:border-b-0 ">
        <div className="flex justify-center items-center">
          <div className="flex flex-col items-start py-8">
            <img
              className="md:h-32 sm:h-20 max-w-full"
              src={logo121}
              alt="Logo"
            ></img>
          </div>

          <div className="px-4 cursor-pointer md:hidden">
            <FiMenu
              size={28}
              onClick={() => setShowMobileMenu(!showMobileMenu)}
            ></FiMenu>
          </div>
        </div>
      </header>
      <nav
        className={`w-full md:py-4 border-t border-b bg-secondary-grayeel md:block left md:sticky md:top-0 md:z-50 ${
          showMobileMenu ? "" : "hidden"
        }`}
      >
        <List />
      </nav>
    </div>
  );
}
export default NavMid;
