import React from "react";
import ReactDOM  from "react-dom/client";
import Nav from './components/layout/NavTop';
import NaVMid from "./components/layout/NaVMid";
import RouteMap from "./components/layout/RouteMap";
import {BrowserRouter as Router} from 'react-router-dom'


function App() {
 
  const root = ReactDOM.createRoot(document.getElementById('head'));
  root.render(
    <Router>
    <RouteMap/>
    <Nav/>
    <NaVMid/>
    </Router>
  
  );
};

export default App;